var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moderate-command-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[(!(_vm.module.model instanceof _vm.NetworksModerateCommandHandlerModule))?_c('switch-input',{staticClass:"mb-0 mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'silent_reaction_commands',
          'hasAnchor': true,
        }
      }}}):_vm._e()],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t(("field_" + (_vm.module.model.snakeCaseType) + "_mute_command_title"))}},[_c('a-alert',{attrs:{"show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t(("field_" + (_vm.module.model.snakeCaseType) + "_mute_command_help_message")))},slot:"message"})]),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'mute_command_text',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'base-api-url': 'api.chatkeeper.info/cabinet',
          'placeholders': _vm.MODERATION_COMMANDS_PLACEHOLDERS,
          'targetToUpload': _vm.groupUploadTarget,
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'editorMode': _vm.MediaEditorMode.TelegramBase,
          'hasAnchor': true,
          'hasMedia': true
        }
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'mute_command_remove',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t(("field_" + (_vm.module.model.snakeCaseType) + "_ban_command_title"))}},[_c('a-alert',{attrs:{"show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t(("field_" + (_vm.module.model.snakeCaseType) + "_ban_command_title_help_message")))},slot:"message"})]),_c('multi-message-editor-with-media-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'ban_command_text',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'base-api-url': 'api.chatkeeper.info/cabinet',
          'placeholders': _vm.MODERATION_COMMANDS_PLACEHOLDERS,
          'targetToUpload': _vm.groupUploadTarget,
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'editorMode': _vm.MediaEditorMode.TelegramBase,
          'hasAnchor': true,
          'hasMedia': true
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'ban_command_remove_action',
          'options': _vm.banActionTypesOptions,
          'clearable': false,
        }
      }}}),(_vm.module.model instanceof _vm.NetworksModerateCommandHandlerModule && _vm.module.model.params.ban_command_remove_action === _vm.BanActionTypesEnum.All)?_c('nested-content',[_c('switch-input',{staticClass:"mb-0 mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'delete_message_in_all_groups',
            'hasAnchor': true,
            'tariffTags': _vm.ultimateLicenseTag
          }
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t(("field_" + (_vm.module.model.snakeCaseType) + "_kick_command_title"))}},[_c('a-alert',{attrs:{"show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t(("field_" + (_vm.module.model.snakeCaseType) + "_kick_command_help_message")))},slot:"message"})]),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'kick_command_text',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'base-api-url': 'api.chatkeeper.info/cabinet',
          'placeholders': _vm.MODERATION_COMMANDS_PLACEHOLDERS,
          'targetToUpload': _vm.groupUploadTarget,
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'editorMode': _vm.MediaEditorMode.TelegramBase,
          'hasAnchor': true,
          'hasMedia': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'kick_command_remove',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'journal_enabled',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }